<template>
  <!-- profile info  -->
  <section id="daftar-instansi">
    <b-row>
      <b-col
        cols="12"
      >
        <template v-if="errorStat">
          <b-alert
            variant="danger"
            class="mb-1"
            show
          >
            <h4 class="alert-heading">
              Error Found
            </h4>
            <div class="alert-body">
              {{ errorMsg }}
            </div>
          </b-alert>
        </template>

        <b-card>
          <!-- search input -->
          <b-row class="mb-1 justify-content-between align-items-center">
            <b-col md="6">
              <b-button
                v-if="userData.group_id === 10"
                variant="outline-primary"
                size="sm"
                class="ml-50"
                @click="downloadFile"
              >
                <feather-icon
                  icon="DownloadIcon"
                  size="12"
                />
                Download .xlsx
              </b-button>
            </b-col>
            <b-col md="4">
              <b-input-group>
                <b-form-input
                  v-model="searchTerm"
                  placeholder="Search"
                  type="text"
                  @keyup.enter="tableSearch"
                />
                <b-input-group-append>
                  <b-button
                    v-if="searchTerm"
                    variant="outline-secondary"
                    class="p-50"
                    @click="searchReset"
                  >
                    <feather-icon
                      icon="XCircleIcon"
                      size="12"
                    />
                  </b-button>
                </b-input-group-append>
                <b-input-group-append>
                  <b-button
                    variant="outline-secondary"
                    @click="tableSearch"
                  >
                    <feather-icon
                      icon="SearchIcon"
                      size="12"
                    />
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-col>
          </b-row>

          <!-- table -->
          <vue-good-table
            mode="remote"
            :total-rows="totalRecords"
            :is-loading.sync="isLoading"
            :columns="columns"
            :rows="rows"
            :pagination-options="{
              enabled: true,
              length:serverParams.length
            }"
            @on-page-change="onPageChange"
            @on-sort-change="onSortChange"
            @on-column-filter="onColumnFilter"
            @on-per-page-change="onPerPageChange"
          >
            <template
              slot="table-row"
              slot-scope="props"
            >
              <span
                v-if="props.column.field === 'name'"
                class="text-nowrap"
              >
                <b-link
                  :to="{ name: 'pengajuan-instansi-detail', params: { id: props.row.instansi_id } }"
                  class="item-review text-primary"
                >
                  {{ props.row.name }}
                </b-link>
              </span>
              <span v-else-if="props.column.field === 'total_anggaran'">
                Rp. {{ (props.row.total_anggaran !== '') ? Number(props.row.total_anggaran).toLocaleString() : 0 }}
              </span>
              <span v-else-if="props.column.field === 'approve'">
                Rp. {{ (props.row.approve !== '') ? Number(props.row.approve).toLocaleString() : 0 }}
              </span>
              <span v-else-if="props.column.field === 'reject'">
                Rp. {{ (props.row.reject !== '') ? Number(props.row.reject).toLocaleString() : 0 }}
              </span>
              <span v-else-if="props.column.field === 'pending_activity'">
                {{ (props.row.pending_activity !== '') ? Number(props.row.pending_activity).toLocaleString() : 0 }}
              </span>
              <span v-else-if="props.column.field === 'total_kegiatan'">
                {{ (props.row.total_kegiatan !== '') ? Number(props.row.total_kegiatan).toLocaleString() : 0 }}
              </span>
              <span v-else-if="props.column.field === 'total_app_infra'">
                {{ (props.row.total_app_infra !== '') ? Number(props.row.total_app_infra).toLocaleString() : 0 }}
              </span>
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>

            <!-- pagination -->
            <template
              slot="pagination-bottom"
              slot-scope="props"
            >
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap">
                    Showing 1 to
                  </span>
                  <b-form-select
                    v-model="serverParams.length"
                    :options="['10','20','50']"
                    class="mx-1"
                    @change="(value)=>props.lengthChanged({currentPerPage:value})"
                  />
                  <span class="text-nowrap"> of {{ props.total }} entries </span>
                </div>
                <div>
                  <b-pagination
                    v-model="selectPage"
                    :total-rows="props.total"
                    :per-page="serverParams.length"
                    first-number
                    last-number
                    align="right"
                    prev-class="prev-item"
                    next-class="next-item"
                    class="mt-1 mb-0"
                    @change="(value)=>props.pageChanged({currentPage:value})"
                  >
                    <template #prev-text>
                      <feather-icon
                        icon="ChevronLeftIcon"
                        size="18"
                      />
                    </template>
                    <template #next-text>
                      <feather-icon
                        icon="ChevronRightIcon"
                        size="18"
                      />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
          </vue-good-table>
        </b-card>
      </b-col>
      <!-- post -->
    </b-row>
  </section>
</template>

<script>
import {
  BRow, BCol, BCard, BLink, BPagination, BFormInput, BButton, BAlert,
  BFormSelect, VBTooltip, BInputGroup, BInputGroupAppend,
} from 'bootstrap-vue'
import { getUserData } from '@/auth/utils'
import { VueGoodTable } from 'vue-good-table'

export default {
  components: {
    BAlert,
    BCard,
    BLink,
    BRow,
    BCol,
    VueGoodTable,
    BInputGroup,
    BInputGroupAppend,
    BPagination,
    BButton,
    BFormInput,
    BFormSelect,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      errorStat: false,
      errorMsg: '',
      yearBudget: localStorage.getItem('tahunAnggaran'),
      userData: getUserData(),
      clearance_id: '',
      clearanceData: '',
      isLoading: false,
      columns: [
        {
          thClass: 'text-center',
          label: 'Nama Instansi',
          field: 'name',
          filterOptions: {
            enabled: true,
            placeholder: 'Nama Instansi',
          },
        },
        {
          thClass: 'text-center',
          label: 'Total Kegiatan',
          type: 'number',
          field: 'total_kegiatan',
          sortable: false,
        },
        {
          thClass: 'text-center',
          label: 'Total Item Kegiatan',
          type: 'number',
          field: 'total_app_infra',
          sortable: false,
        },
        {
          thClass: 'text-center',
          label: 'Total Anggaran',
          type: 'number',
          field: 'total_anggaran',
          sortable: false,
        },
        {
          thClass: 'text-center',
          label: 'Anggaran Disetujui',
          type: 'number',
          field: 'approve',
          sortable: false,
        },
        {
          thClass: 'text-center',
          label: 'Anggaran Ditunda',
          type: 'number',
          field: 'reject',
          sortable: false,
        },
        {
          thClass: 'text-center',
          label: 'Menunggu Verifikasi',
          type: 'number',
          field: 'pending_activity',
          sortable: false,
        },
      ],
      rows: [],
      searchTerm: '',
      totalRecords: 0,
      selectPage: 1,
      serverParams: {
        tahun_anggaran: localStorage.getItem('tahunAnggaran'),
        filters: [],
        sort_by: [
          {
            column: 'name',
            asc_desc: 'asc',
          },
        ],
        start: 0,
        length: 20,
      },
    }
  },
  beforeRouteEnter(to, from, next) {
    const role = Number(localStorage.getItem('roleId'))
    if (role > 99) {
      next('/pengajuan')
    } else {
      next()
    }
  },
  mounted() {
    this.onColumnFilter({
      columnFilters: {
        tahun_anggaran: this.yearBudget,
      },
    })
    this.loadItems()
  },
  methods: {
    downloadFile() {
      const params = this.serverParams
      this.$http.post(`/analytic/instansi/export-instansi-data?token=${localStorage.getItem('userToken')}`, params)
        .then(res => {
          if (res.data.status === 'success') {
            const link = document.createElement('a')
            link.href = `${process.env.VUE_APP_API_URL}/document/excel/download/${res.data.data.filename}?token=${localStorage.getItem('userToken')}`
            link.click()
          }
          return true
        })
    },
    tableSearch() {
      this.updateParams({ keyword: this.searchTerm })
      this.loadItems()
    },
    searchReset() {
      this.searchTerm = ''
      this.updateParams({ keyword: '' })
      this.loadItems()
    },
    updateParams(newProps) {
      this.serverParams = { ...this.serverParams, ...newProps }
    },
    onPageChange(params) {
      this.updateParams({ start: (params.currentPage - 1) * this.serverParams.length })
      this.loadItems()
    },
    onPerPageChange(params) {
      this.updateParams({ length: params.currentPerPage })
      this.loadItems()
    },
    onSortChange(params) {
      params.map(param => {
        this.updateParams({
          sort_by: [{
            asc_desc: param.type,
            column: param.field,
          }],
        })
        return true
      })
      this.loadItems()
    },
    onColumnFilter(params) {
      const filterz = []
      filterz.push({
        column: 'tahun_anggaran',
        value: this.yearBudget,
      })
      Object.entries(params.columnFilters).forEach(entry => {
        const [key, valuez] = entry
        if (valuez !== '') {
          filterz.push({
            column: key,
            value: valuez,
          })
        }
      })
      this.updateParams({
        filters: filterz,
      })
      this.loadItems()
    },
    loadItems() {
      document.getElementById('loading-bg').style.display = 'block'
      this.$http.post(`/analytic/instansi?token=${localStorage.getItem('userToken')}`, this.serverParams)
        .then(res => {
          this.rows = []
          if (res.data.status === 'success') {
            this.errorStat = false
            this.rows = res.data.data.data
            this.totalRecords = res.data.data.found
          } else {
            this.totalRecords = 0
          }
          document.getElementById('loading-bg').style.display = 'none'
        })
        .catch(error => {
          document.getElementById('loading-bg').style.display = 'none'
          this.rows = []
          this.totalRecords = 0
          this.errorStat = true
          this.errorMsg = error.response.data.error
        })
    },
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
